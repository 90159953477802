.body {
  font-family: "Montserrat", sans-serif;
  font-size: 1.1rem;
  line-height: 1.45;
}

.section {
  margin-bottom: 3%;
}

.projects {
  width: 100%;
  max-width: 1140px;
  margin-right: auto;
  margin-left: auto;
}

.name-text {
  padding-top: 50px;
  font-size: 40px;
  line-height: 34px;
  font-weight: 100;
}
.paragraph-light {
  opacity: 0.6;
  font-size: 30px;
}
.mochiyPopOne {
  font-family: "MochiyPopOne-Regular", sans-serif;
}
.heading-jumbo {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 64px;
  line-height: 80px;
  text-transform: none;
}
.textFont {
  font-family: "Montserrat", sans-serif;
}

.nameFont {
  font-family: "Dawning of a New Day", cursive;
}

/*  */
.motto-wrap {
  width: 80%;
  margin-right: auto;
  margin-bottom: 50px;
  margin-top: 50px;
  margin-left: auto;
  text-align: center;
}
.label {
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.label.cc-light {
  opacity: 0.6;
}
.heading-jumbo-small {
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 36px;
  line-height: 50px;
  font-weight: 400;
  text-transform: none;
}

/*  */
.w3-row-padding img {
  margin-bottom: 12px;
}

/* about*/
.w3-center {
  text-align: center;
}

.footer {
  text-align: center;
}
.w-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 940px;
}

/* Project */
.title-image {
  width: 100%;
  transform: rotate(0deg);
  position: absolute;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.b-example-divider {
  height: 3rem;
  background-color: rgba(0, 0, 0, .03);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 0.5em 1.5em rgb(0 0 0 / 10%), inset 0 0.125em 0.5em rgb(0 0 0 / 15%);
}

.topPadding {
  margin-top: 3%;
}

.rounded-6 {
  border-radius: 1.2rem;
}

.background {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: 0;
    background-size: cover;
    background-position: center;
    overflow: hidden;
}

.card-cover {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
}
    

.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
 }


.image-container {
  position: relative;
  margin-bottom: 5px;
  margin-left: 15px;
  pointer-events: auto; /* Ensure that the container can receive click events */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
background-color: rgba(0, 0, 0, 0.6); /* Adjust the color and opacity as needed */
  opacity: 0;
  transition: opacity 0.3s ease;
  pointer-events: none; /* Ensure that the overlay doesn't interfere with click events on the image */
}

.image-container:hover .overlay {
  opacity: 1;
}

.overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 14px;
  font-weight: bold;
}


.image-carousel {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.center-image {
  width: 400px; /* Adjust width as needed */
  height: 300px; /* Adjust height as needed */
  overflow: hidden;
  margin-bottom: 20px; /* Adjust margin as needed */
}

.center-image-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbnail-container {
  display: flex;
  justify-content: center;
}

.thumbnail {
  width: 100px; /* Adjust width as needed */
  height: 75px; /* Adjust height as needed */
  margin: 0 5px; /* Adjust margin as needed */
  cursor: pointer;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.thumbnail:hover,
.selected {
  opacity: 1;
}

.link {
  color: black; 
  font-weight: bold;
}

.link:hover {
  color: black; 
  font-weight: bolder;
}

.headerTitle {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  color: black;
  font-weight: bolder;
  font-size: 34px;
  text-decoration: none;
  letter-spacing: 1px;
}
.subTitle {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  color: black;
  font-weight: 1000;
  font-size: 16px;
  text-decoration: none;
  
}
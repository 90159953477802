.navbar {
  color: black;
}

.nav-item {
  font-size: 18px;
  padding: 0 10px;
  color: black;
  font-weight: lighter;
}

.nav-link {
  letter-spacing: 1px;
  color: black;
}

.navbar-brand {
  font-size: 1.2rem;
  color: black;
}

.nav-link:hover,
.nav-link:focus {
  color: black;
  font-weight: normal;
}


.sidebar {
    position: fixed;
}

.titleStyle {
  font-family: "Dawning of a New Day", cursive;
  margin: 0;
  color: black;
  font-weight: 700;
  font-size: 45px;
  text-decoration: none;
  letter-spacing: 1.5px;
}

.titleStyle:hover {
  color: black;
}